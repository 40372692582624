import React, { Component }  from 'react';
import './loading.css';

export default class Loading extends Component {
  render() {
    return (
      <div className="loadingio-spinner-spin-v4k91vz6yfs"><div className="ldio-54676n8l7tv">
      <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
      </div></div>
    )
  }
}
